.animation {
    animation-duration: 1s;
    animation-name: slidein;

  }
  
  @keyframes slidein {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  
}