body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* MATERIAL - FONT */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(assets/fonts/MaterialIcons-Regular.woff) format('woff'),
       url(assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
 font-family: 'Material Icons';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;  /* Preferred icon size */
 display: inline-block;
 line-height: 1;
 text-transform: none;
 letter-spacing: normal;
 word-wrap: normal;
 white-space: nowrap;
 direction: ltr;

 /* Support for all WebKit browsers. */
 -webkit-font-smoothing: antialiased;
 /* Support for Safari and Chrome. */
 text-rendering: optimizeLegibility;

 /* Support for Firefox. */
 -moz-osx-font-smoothing: grayscale;

 /* Support for IE. */
 font-feature-settings: 'liga';
}