.btn-d{
    padding: '9px';
    color:#fff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 10px;
    width:200px;
    


    background: rgba(248,78,48,1);
    background: -moz-linear-gradient(left, rgba(248,78,48,1) 0%, rgba(225,5,12,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(248,78,48,1)), color-stop(100%, rgba(225,5,12,1)));
    background: -webkit-linear-gradient(left, rgba(248,78,48,1) 0%, rgba(225,5,12,1) 100%);
    background: -o-linear-gradient(left, rgba(248,78,48,1) 0%, rgba(225,5,12,1) 100%);
    background: -ms-linear-gradient(left, rgba(248,78,48,1) 0%, rgba(225,5,12,1) 100%);
    background: linear-gradient(to right, rgba(248,78,48,1) 0%, rgba(225,5,12,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f84e30', endColorstr='#e1050c', GradientType=1 );
}

.btn-d:hover{

}

.support-links{
    color:#002F6C;
    text-decoration: none;
    font-weight: 700;
}
.support-links:hover{
    color:#000000;
}
iframe {  
    border: none; 
    display:block
}

input:-internal-autofill-selected{
    
}

